<template>
  <div class="g-section">
    <div class="g-wrap main-container">
      <div class="wrap-top">
        <span class="section-title">我的目标岗位</span>
        <div v-if="!hasTarget">
          <el-button type="primary" size="small" plain>职业测评</el-button>
          <el-button type="primary" size="small" @click="dialogVisible=true">选择目标岗位</el-button>
        </div>
      </div>
      <div class="wrap-list mt-20" v-if="hasTarget">
        <div class="target-item">
          <el-row>
            <el-col :span="2">目标岗位</el-col>
            <el-col :span="22">
              <el-row>
                <el-col :span="2" class="target-title">总监</el-col>
                <el-col :span="16">
                  <el-progress :text-inside="true" :stroke-width="30" :percentage="20" status="exception"></el-progress>
                </el-col>
                <el-col :span="6">
                  <span class="target-btn">职业能力</span>
                  <span class="target-btn">提升课程</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row class="mt-20">
            <el-col :span="2">晋升通道</el-col>
            <el-col :span="22">
              <el-row class="mb-20">
                <el-col :span="2" class="target-title">经理</el-col>
                <el-col :span="16">
                  <el-progress :text-inside="true" :stroke-width="20" :percentage="40" status="warning"></el-progress>
                </el-col>
                <el-col :span="6">
                  <span class="target-btn">职业能力</span>
                  <span class="target-btn">提升课程</span>
                </el-col>
              </el-row>
              <el-row class="mb-20">
                <el-col :span="2" class="target-title">主管</el-col>
                <el-col :span="16">
                  <el-progress :text-inside="true" :stroke-width="20" :percentage="80" status="warning"></el-progress>
                </el-col>
                <el-col :span="6">
                  <span class="target-btn">职业能力</span>
                  <span class="target-btn" @click="hasGroup=true">提升课程</span>
                </el-col>
              </el-row>
              <el-row class="mb-20">
                <el-col :span="2" class="target-title">工程师</el-col>
                <el-col :span="16">
                  <el-progress :text-inside="true" :stroke-width="20" :percentage="100" status="success"></el-progress>
                </el-col>
                <el-col :span="6">
                  <span class="target-btn">职业能力</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="g-wrap main-container" v-if="hasGroup">
      <div class="wrap-top">
        <span class="section-title">成长路径</span>
      </div>
      <div class="wrap-list mt-20">
        <el-timeline :reverse="reverse">
          <el-timeline-item v-for="(activity, index) in activities" :key="index" :timestamp="activity.timestamp">
            {{activity.content}}
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
    <el-dialog title="选择你的目标岗位" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <span>从系统已支持的岗位选，按行业，岗位类型选</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleChoseTarget">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      reverse: false,
      hasTarget: false,
      dialogVisible:false,
      hasGroup: false,
      activities: [{
        content: '完成了【架构师的必备素质】课程学习',
        timestamp: '2018-09-19'
      }, {
        content: '达成能力【良好的项目质量控制】',
        timestamp: '2018-08-15'
      }, {
        content: '达成中级能力【产品设计力】',
        timestamp: '2018-07-15'
      }, {
        content: '完成了基础课程',
        timestamp: '2018-06-15'
      }, {
        content: '入职深圳智邻科技【工程师】',
        timestamp: '2018-05-13'
      }, {
        content: '选择了目标岗位【总监】',
        timestamp: '2018-04-11'
      }, {
        content: '加入广东轻工职业技术学院',
        timestamp: '2018-03-11'
      }]
    }
  },
  methods:{
    handleChoseTarget(){
      this.dialogVisible=false
      this.hasTarget=true
    }
  }

}
</script>
<style lang="scss" scoped>
.main-container {
  flex-direction: column;
  padding: 20px 0px;
}
.g-section {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.wrap-top {
  display: flex;
  width: 100%;
  height: 32px;
  justify-content: space-between;
  align-items: center;
}
.target-btn {
  margin-left: 20px;
  color: #409ffe;
  padding: 4px 8px;
  border: 1px solid #409ffe;
  cursor: pointer;
  font-size: 12px;
  border-radius: 4px;
  &:hover {
    color: #ffffff;
    background: #409ffe;
  }
}
.target-title {
  &::before {
    content: '';
    vertical-align: 1px;
    border-radius: 4px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #409ffe;
    margin-right: 5px;
  }
}
</style>